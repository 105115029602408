<template>
    <div>
        <PageTitle :title="applyType == '护理咨询' ? '病区列表' : '科室列表'" backTo="/home" fixed smallBackground :leftArrow="false">
            <div class="searchContainer">
                <van-field v-model="searchValue" @input="searchDoctor" :placeholder="applyType == '护理咨询' ? '搜索护士' : '搜索医生'" input-align="center" left-icon="search"/>
                <List :width="80" v-show="doctors.length && this.searchValue">
                    <ListItem :leftWidth="90" :rightWidth="10" style="vertical-align: middle;" :to="'/consultOnline/0/' + doctor.departmentid + '/' + doctor.jobnumber" v-for="doctor in doctors" :key="doctor.doctorid">
                        <template #left>
                            <h6>
                              <span>{{ doctor.doctorname }}</span>
                              {{doctor.department}} {{doctor.area }}
                            </h6>
                            <p>{{ doctor.introdution }}</p>
                            <p>{{ doctor.clinic }}</p>
                        </template>
                        <template #right>
                            <van-icon name="play-circle" />
                        </template>
                    </ListItem>
                </List>
                <p>
                    <span>历史搜索:</span>
                    <van-tag @click="searchValue = item; searchDoctorHistory();" v-for="(item, index) in searchHistory" :key="index" round type="success">{{ item }}</van-tag>
                    <van-icon name="cross" @click="searchHistory = [];"/>
                </p>
            </div>
        </PageTitle>

        <List>
            <ListItem :leftWidth="50" :rightWidth="50" v-for="deptClass in depts" :key="deptClass.code">
                <template #top>
                    <p>{{ deptClass.codeName }}</p>
                </template>
                <template #bottom>
                    <van-row>
                        <van-col span="12" v-for="dept in deptClass.departments" :key="dept.departmentId"><van-button :to="'/doctors/' + dept.departmentId" type="primary" size="normal" block>{{ dept.departmentName }}</van-button></van-col>
                    </van-row>
                </template>
            </ListItem>
        </List>
    </div>
</template>

<script>
    // @ is an alias to /src
    import PageTitle from '@/components/PageTitle.vue';
    import List from '@/components/home-page/List.vue';
    import ListItem from '@/components/home-page/ListItem.vue';

    import { getDepartment, searchDoctors } from '@/api/hospital.js';

    export default {
        components: {
            PageTitle,
            List,
            ListItem
        },
        data(){
            return {
                searchValue: '',
                searchHistory: [],
                doctors: [],
                depts: [],
                applyType: '',
              timeout: null,
            }
        },
        methods:{
            searchDoctor(){
              //搜索医生
              this.searchHistory.unshift(this.searchValue);
              if(this.searchHistory.length > 3) this.searchHistory.pop();

              this.searchDoctorHistory();
            },
            searchDoctorHistory(){
              searchDoctors(this.searchValue, this.applyType).then(
                  (result)=>{
                    if(this.searchValue){
                      this.doctors = result;
                    }else{
                      this.doctors = [];
                    }
                  },()=>{
                    this.doctors = [];
                  });
            }
        },
        beforeCreate(){
          getDepartment(this.$route.params.type || '云门诊').then(
              (result)=>{
                this.depts = result;
              },
              ()=>{}
          ).finally(()=>{
            this.applyType = this.$route.params.type || '云门诊';
            localStorage.setItem('applyType', this.applyType);
          });
        },
        created(){
            this.searchHistory = JSON.parse(localStorage.getItem('searchHistory')) || [];
        },
        destroyed(){
            localStorage.setItem('searchHistory', JSON.stringify(this.searchHistory));
        }
    }
</script>

<style scoped="" lang="less">
    @import "../style/var.less";

    .searchContainer{
        width: 80%;
        margin: 10px auto 0;

        .van-field{
            border: 1.5px solid @green;
            padding: 4px 16px;
            border-radius: 8px;
            box-shadow: 0px 0px 5px 0px @gray-6 inset;
        }

        .listContainer{
            position: fixed;
            z-index: 1;
            max-height: 310px;
            overflow-y: auto;

            .infoContainer{
                padding: 10px;
                margin-bottom: 0;
                background-color: @gray-1;

                .left{
                    h6{
                        text-align: left;
                        margin: 0 0 5px;

                        span{
                            font-size: 15px;
                        }
                    }

                    p{
                        white-space: nowrap; //保证文本内容不会自动换行，如果多余的内容会在水平方向撑破单元格。
                        overflow: hidden; //隐藏超出单元格的部分。
                        text-overflow: ellipsis; //将被隐藏的那部分用省略号代替。
                    }
                }
            }
        }

        p{
            font-size: @font-size-sm;
            padding: 5px 0;
            margin: 0;
            color: @green;

            span{
                margin-right: 2px;
            }
            .van-icon{
                font-size: @font-size-md;
                float: right;
                position: relative;
                top: 2px;
            }
        }
    }

    .listContainer{
        margin-top: 5px;

        .infoContainer{
            padding: 10px;

            .top p{
                text-align: center;
                margin: 0 0 10px;
            }

            .bottom .van-row{
                .van-col:nth-child(even){
                    padding-left: 5px;
                    margin-bottom: 5px;
                }
                .van-col:nth-child(odd){
                    padding-right: 5px;
                    margin-bottom: 5px;
                }
            }
        }
    }
</style>
